import axiosInstance from "app/axios-interceptor";

export const getEmployees = async (params, signal) => {
  const { data } = await axiosInstance.get(`employee`, { params, signal });
  return data;
};

export const getEmployeeDetail = async (id, signal) => {
  const { data } = await axiosInstance.get(`employee/${id}`, { signal });
  return data;
};

export const syncEmployee = async (signal) => {
  const { data } = await axiosInstance.get(`employee/sync`, { signal });
  return data;
};

export const syncContract = async (signal) => {
  const { data } = await axiosInstance.get(`contract/sync`, { signal });
  return data;
};
