import React from "react";
import classNames from "classnames";

const Button = ({
  theme = "",
  outline = false,
  size = "",
  type = "button",
  className = "",
  onClick,
  disabled = false,
  startIcon = null,
  endIcon = null,
  children,
  iconOnly = false,
}) => {
  const themeHandler = (val) => {
    switch (val) {
      case "primary":
        return `btn-primary`;
      case "secondary":
        return `btn-secondary`;
      case "accent":
        return `btn-accent`;
      case "info":
        return `btn-info`;
      case "success":
        return `btn-success`;
      case "warning":
        return `btn-warning`;
      case "error":
        return `btn-error`;
      case "active":
        return `btn-active`;
      case "ghost":
        return `btn-ghost`;
      case "link":
        return `btn-link`;
      default:
        return "";
    }
  };

  const sizeHandler = (val) => {
    switch (val) {
      case "xl":
        return `btn-xl`;
      case "lg":
        return `btn-lg`;
      case "md":
        return `btn-md`;
      case "sm":
        return `btn-sm`;
      case "xs":
        return `btn-xs`;
      default:
        return "";
    }
  };

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        ["btn", "capitalize", sizeHandler(size), themeHandler(theme)],
        { "btn-outline": outline },
        { "gap-2": (startIcon || endIcon) && !iconOnly },
        ...className.split(" ")
      )}
    >
      {startIcon}
      {children}
      {endIcon}
    </button>
  );
};

export default Button;
