import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ButtonPrimary = ({
  className = "",
  onClick,
  children,
  startIcon,
  endIcon,
}) => {
  return (
    <button
      className={classNames(
        [
          "btn capitalize bg-primary-400 text-white font-medium px-4 py-2 shadow rounded-md border-primary-400",
          "hover:bg-primary-500 hover:border-primary-500",
          className,
        ],
        { "gap-2": startIcon || endIcon }
      )}
      onClick={onClick}
    >
      {startIcon}
      {children}
      {endIcon}
    </button>
  );
};

ButtonPrimary.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
};

export default ButtonPrimary;
