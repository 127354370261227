import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { isEmpty } from "lodash";
import { store } from "app/store";
import { generateErrorMessage } from "utils/generate-error-message";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
});

export const hrAxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_HR_API_URL}`
});

const requestInterceptor = (config) => {
  const accessToken = store?.getState()?.auth?.accessToken;
  if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
};

const responseInterceptor = (response) => {
  if (response && "error" in response.data && !isEmpty(response.data?.error)) {
    enqueueSnackbar(generateErrorMessage(response), {
      autoHideDuration: 2500,
      variant: "error"
    });
    return Promise.reject(response);
  }
  return response;
};

const errorInterceptor = (error) => {
  if (axios.isCancel(error)) {
    return;
  }
  if (error.response.status === 401) {
    localStorage.clear();
    window.location.href = "";
    return;
  }
  enqueueSnackbar(generateErrorMessage(error), {
    autoHideDuration: 2500,
    variant: "error"
  });
  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);
axiosInstance.interceptors.request.use(requestInterceptor);

hrAxiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);
hrAxiosInstance.interceptors.request.use(requestInterceptor);

export default axiosInstance;
